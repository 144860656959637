import React from 'react';
import {graphql, Link, StaticQuery} from 'gatsby';

import Graphic from 'components/Graphic.js';
import i18n from 'data/i18n.en.json';

export default class Footer extends React.Component {
    render() {
        return (
            <StaticQuery
                query={graphql`
                    {
                        home: wordpressPage(wordpress_id: {eq: 2}) {
                            path
                            title
                        }
                        privacy: wordpressPage(wordpress_id: {eq: 225}) {
                            path
                            title
                        }
                        terms: wordpressPage(wordpress_id: {eq: 223}) {
                            path
                            title
                        }
                        contact: wordpressPage(wordpress_id: {eq: 2634}) {
                            path
                        }
                    }
                `}
                render={(data) => (
                    <footer>
                        <nav>
                            <Link className="mark" to={data.home.path}>
                                <Graphic name="iconHealthshare" />
                                {'HealthShare'}
                            </Link>
                            <Link to={data.privacy.path}>
                                {data.privacy.title}
                            </Link>
                            <Link to={data.terms.path}>
                                {data.terms.title}
                            </Link>
                            <Link
                                className="cta inverted"
                                to={data.contact.path}
                            >
                                {i18n.menu.contact}
                            </Link>
                        </nav>
                    </footer>
                )}
            />
        );
    }
}
