import React from 'react';
import {graphql, Link, StaticQuery} from 'gatsby';

import DropDown from 'components/DropDown.js';
import Graphic from 'components/Graphic.js';
import i18n from 'data/i18n.en.json';

export default class Menu extends React.Component {
    getIconRef(name) {
        const camelised = name
            .split('-')
            .map((word) => word.replace(/./, (char) => char.toUpperCase()))
            .join('');
        return `icon${camelised}`;
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    {
                        home: wordpressPage(wordpress_id: {eq: 2}) {
                            acf {
                                override
                            }
                            path
                            title
                        }
                        audiences: wordpressPage(wordpress_id: {eq: 11}) {
                            acf {
                                override
                            }
                            path
                            title
                        }
                        audiencePages: allWordpressWpAudiences(
                            sort: {fields: title}
                        ) {
                            edges {
                                node {
                                    acf {
                                        override
                                    }
                                    path
                                    slug
                                    title
                                }
                            }
                        }
                        solutions: wordpressPage(wordpress_id: {eq: 14}) {
                            acf {
                                override
                            }
                            path
                            title
                        }
                        solutionPages: allWordpressWpSolutions(
                            sort: {fields: title}
                        ) {
                            edges {
                                node {
                                    path
                                    slug
                                    title
                                }
                            }
                        }
                        company: wordpressPage(wordpress_id: {eq: 17}) {
                            acf {
                                override
                            }
                            path
                            title
                        }
                        companyPages: allWordpressPage(
                            filter: {wordpress_parent: {eq: 17}}
                            sort: {fields: menu_order}
                        ) {
                            edges {
                                node {
                                    path
                                    slug
                                    title
                                }
                            }
                        }
                        blog: wordpressPage(wordpress_id: {eq: 53}) {
                            acf {
                                override
                            }
                            path
                            title
                        }
                        contact: wordpressPage(wordpress_id: {eq: 2634}) {
                            path
                        }
                    }
                `}
                render={(data) => (
                    <header className="menu">
                        <Link className="mark" to={data.home.path}>
                            <Graphic name="iconHealthshare" />
                            {'HealthShare'}
                        </Link>
                        <DropDown title={data.audiences.title}>
                            {data.audiencePages.edges.map(({node}) => (
                                <li key={node.path}>
                                    <Link to={node.path}>
                                        <Graphic
                                            name={this.getIconRef(node.slug)}
                                        />
                                        {node.acf.override
                                            ? node.acf.override
                                            : node.title}
                                    </Link>
                                </li>
                            ))}
                        </DropDown>
                        <DropDown title={data.solutions.title}>
                            {data.solutionPages.edges
                                .filter(
                                    ({node}) =>
                                        // prevents child pages from displaying
                                        // since we do not get this back as a value
                                        node.path.replace(/[^/]/g, '')
                                            .length === 3,
                                )
                                .map(({node}) => (
                                    <li key={node.path}>
                                        <Link to={node.path}>
                                            <Graphic
                                                name={this.getIconRef(
                                                    node.slug,
                                                )}
                                            />
                                            {node.title}
                                        </Link>
                                    </li>
                                ))}
                        </DropDown>
                        <DropDown title={data.company.title}>
                            {data.companyPages.edges.map(({node}) => (
                                <li key={node.path}>
                                    <Link to={node.path}>
                                        <Graphic
                                            name={this.getIconRef(node.slug)}
                                        />
                                        {node.title}
                                    </Link>
                                </li>
                            ))}
                        </DropDown>
                        <Link to={data.blog.path}>
                            {data.blog.acf && data.blog.acf.override
                                ? data.blog.acf.override
                                : data.blog.title}
                        </Link>
                        <Link className="cta secondary" to={data.contact.path}>
                            {i18n.menu.contact}
                        </Link>
                    </header>
                )}
            />
        );
    }
}
