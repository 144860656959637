import React from 'react';
import PropTypes from 'prop-types';

import sprite from 'assets/sprite.js';

export class Graphic extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
    };

    getGraphicFromSprite() {
        if (sprite[this.props.name]) {
            return sprite[this.props.name];
        }
        return false;
    }

    render() {
        return this.getGraphicFromSprite();
    }
}

export default Graphic;
