import React from 'react';
import PropTypes from 'prop-types';

import Graphic from 'components/Graphic.js';

export default class DropDown extends React.Component {
    static propTypes = {
        expanded: PropTypes.bool,
        title: PropTypes.string.isRequired,
    };

    static defaultProps = {
        expanded: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            expanded: this.props.expanded,
        };
        this.handleDocumentPress = this.handleDocumentPress.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
    }

    componentDidMount() {
        document.documentElement.addEventListener(
            'mousedown',
            this.handleDocumentPress,
        );
    }

    componentWillUnmount() {
        document.documentElement.removeEventListener(
            'mousedown',
            this.handleDocumentPress,
        );
    }

    handleDocumentPress(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                expanded: false,
            });
        }
    }

    handleToggle() {
        this.setState({
            expanded: !this.state.expanded,
        });
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    render() {
        return (
            <nav
                aria-expanded={this.state.expanded}
                className="drop-down"
                ref={this.setWrapperRef}
            >
                <button onClick={this.handleToggle}>
                    {this.props.title}
                    <Graphic name="dropDown" />
                </button>
                {this.state.expanded && <ul>{this.props.children}</ul>}
            </nav>
        );
    }
}
