import React from 'react';
import PropTypes from 'prop-types';

import Footer from 'components/Footer.js';
import Menu from 'components/Menu.js';

import 'assets/styles/screen.less';

export default class Layout extends React.Component {
    static propTypes = {
        audience: PropTypes.string,
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        solution: PropTypes.string,
    };

    render() {
        return (
            <>
                <Menu />
                <main
                    className={this.props.className}
                    data-audience={this.props.audience}
                    data-solution={this.props.solution}
                    role="main"
                >
                    {this.props.children}
                </main>
                <Footer />
            </>
        );
    }
}
